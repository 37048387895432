import React from "react";

const ShoppingList = () => {
  return (
    <div className="shoppingListCont">
      <h1>Shopping List</h1>
    </div>
  );
};

export default ShoppingList;
